var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container base-page d-flex" },
    [
      _c(
        "div",
        { staticClass: "w-100 align-items-center my-auto" },
        [
          _vm._t("page-title"),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "w-100 my-5" },
            _vm._l(_vm.page, function (content, index) {
              return _c(
                "b-col",
                {
                  key: `content-${index}`,
                  staticClass: "col-md-4 animate__animated animate__slideInUp",
                },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "content-card",
                      attrs: { "body-class": "text-center p-0 w-100" },
                      on: {
                        click: function ($event) {
                          return _vm.onContentTileClick(index)
                        },
                      },
                    },
                    [
                      _c("b-card-body", [
                        _c("div", { staticClass: "content-img-bg mx-auto" }, [
                          _c("img", { attrs: { src: content.img, alt: "" } }),
                        ]),
                        _vm._v(" "),
                        _c("h2", { staticClass: "nav-link active my-3" }, [
                          _vm._v(_vm._s(content.title)),
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          domProps: { innerHTML: _vm._s(content.subtitle) },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "content-modal",
          attrs: {
            id: "content-modal",
            size: "md",
            "hide-header": "",
            "hide-footer": "",
            "modal-class": "base-page-modal",
            "body-class": ["content-modal", _vm.contentModalClass],
          },
        },
        [
          _c(
            "b-row",
            [
              _vm._l(_vm.page, function (content, index) {
                return _c("b-col", { key: `content-modal-${index}` }, [
                  _c(
                    "div",
                    {
                      staticClass: "text-center content-icon",
                      class: { active: _vm.selectedContentIndex === index },
                    },
                    [
                      _c(
                        "div",
                        {
                          key: `content-img-${index}`,
                          staticClass: "content-img-bg mx-auto",
                          on: {
                            click: function ($event) {
                              return _vm.onContentClick(index)
                            },
                          },
                        },
                        [_c("img", { attrs: { src: content.img, alt: "" } })]
                      ),
                      _vm._v(" "),
                      _c("h6", { staticClass: "my-2 content-title" }, [
                        _vm._v(_vm._s(content.title)),
                      ]),
                    ]
                  ),
                ])
              }),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                _c(
                  "div",
                  {
                    key: `content-body-${_vm.selectedContentIndex}`,
                    staticClass: "mx-4 mt-4",
                    class: _vm.contentClass,
                  },
                  [_vm._t("selected-content")],
                  2
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }