<template>
  <div class="main">
    <div class="container d-flex">
      <base-page
        :page="policiesPage"
        @selectedIndex="onContentSelect"
        contentClass="policy-content"
      >
        <template #page-title>
          <h1 class="w-100 text-center" v-html="$t('pages.policies.title')"></h1>
        </template>
        <template #selected-content>
          <div class="text-center mb-3">
            <blockquote class="m-0">
              <p class="policy-subtitle mb-2">
                {{ policiesPage[selectedPolicyIndex].subtitle }}
              </p>
            </blockquote>
          </div>
          <div class="d-flex mb-2">
            <i class="iconsminds-light-bulb-2 align-self-center mr-2"></i>
            <h4 class="d-inline mb-0 align-self-center">
              {{ $t('pages.policies.overview-title') }}
            </h4>
          </div>
          <p v-html="policiesPage[selectedPolicyIndex].overview"></p>
          <div class="d-flex mb-2">
            <i class="iconsminds-information align-self-center mr-2"></i>
            <h4 class="d-inline mb-0 align-self-center">
              {{ $t('pages.policies.covered-title') }}
            </h4>
          </div>
          <div v-html="formatContent(policiesPage[selectedPolicyIndex].covered)"></div>
        </template>
      </base-page>
    </div>
  </div>
</template>

<script>
import BasePage from '@/components/Pages/BasePage.vue'
import { policiesPage } from '@/data/pageContents'
import { formatBasePageContent } from '@/constants/config'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      policiesPage,
      selectedPolicyIndex: 0
    }
  },
  methods: {
    onContentSelect(index) {
      let self = this
      self.selectedPolicyIndex = index
    },
    formatContent(content) {
      return formatBasePageContent(content)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: calc(100vh - 300px);
}

h1 {
  font-family: 'Nunito Light';
}

h1 span {
  font-family: 'Nunito Bold';
}

.policy-card:hover {
  cursor: pointer;
  transform: scale(1.08);
  box-shadow: 3px 3px 12px 0 rgba(0, 0, 0, 0.2);
}

.policy-content {
  .policy-title {
    font-family: 'Nunito Light';
  }

  .policy-subtitle {
    font-size: 1rem;
    font-family: 'Share Tech Mono', 'Nunito Bold';
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
}

.fade-enter-active {
  transition-delay: 0.2s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

blockquote {
  font-family: Georgia, serif;
  position: relative;
  padding: 0.5em 1em 0 1em;
  display: inline-block;
}
blockquote:before {
  font-family: Georgia, serif;
  position: absolute;
  font-size: 1.5em;
  line-height: 1;
  top: 0.2em;
  left: 0;
  content: '\201C';
}
blockquote:after {
  font-family: Georgia, serif;
  position: absolute;
  float: right;
  font-size: 1.5em;
  line-height: 1;
  top: 0.2em;
  right: 0;
  content: '\201D';
}
blockquote footer {
  padding: 0 2em 0 0;
  text-align: right;
}
blockquote cite:before {
  content: '\2013';
}
</style>
