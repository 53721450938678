import { render, staticRenderFns } from "./BasePage.vue?vue&type=template&id=4aa56fbb&scoped=true"
import script from "./BasePage.vue?vue&type=script&lang=js"
export * from "./BasePage.vue?vue&type=script&lang=js"
import style0 from "./BasePage.vue?vue&type=style&index=0&id=4aa56fbb&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aa56fbb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/app/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4aa56fbb')) {
      api.createRecord('4aa56fbb', component.options)
    } else {
      api.reload('4aa56fbb', component.options)
    }
    module.hot.accept("./BasePage.vue?vue&type=template&id=4aa56fbb&scoped=true", function () {
      api.rerender('4aa56fbb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Pages/BasePage.vue"
export default component.exports