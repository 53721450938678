var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      { staticClass: "container d-flex" },
      [
        _c("base-page", {
          attrs: { page: _vm.policiesPage, contentClass: "policy-content" },
          on: { selectedIndex: _vm.onContentSelect },
          scopedSlots: _vm._u([
            {
              key: "page-title",
              fn: function () {
                return [
                  _c("h1", {
                    staticClass: "w-100 text-center",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("pages.policies.title")),
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "selected-content",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center mb-3" }, [
                    _c("blockquote", { staticClass: "m-0" }, [
                      _c("p", { staticClass: "policy-subtitle mb-2" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.policiesPage[_vm.selectedPolicyIndex].subtitle
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex mb-2" }, [
                    _c("i", {
                      staticClass:
                        "iconsminds-light-bulb-2 align-self-center mr-2",
                    }),
                    _vm._v(" "),
                    _c(
                      "h4",
                      { staticClass: "d-inline mb-0 align-self-center" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("pages.policies.overview-title")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.policiesPage[_vm.selectedPolicyIndex].overview
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex mb-2" }, [
                    _c("i", {
                      staticClass:
                        "iconsminds-information align-self-center mr-2",
                    }),
                    _vm._v(" "),
                    _c(
                      "h4",
                      { staticClass: "d-inline mb-0 align-self-center" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("pages.policies.covered-title")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.formatContent(
                          _vm.policiesPage[_vm.selectedPolicyIndex].covered
                        )
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }