<template>
  <div class="container base-page d-flex">
    <div class="w-100 align-items-center my-auto">
      <slot name="page-title"></slot>
      <b-row class="w-100 my-5">
        <b-col
          v-for="(content, index) in page"
          :key="`content-${index}`"
          class="col-md-4 animate__animated animate__slideInUp"
        >
          <b-card
            body-class="text-center p-0 w-100"
            class="content-card"
            @click="onContentTileClick(index)"
          >
            <b-card-body>
              <div class="content-img-bg mx-auto">
                <img :src="content.img" alt="" />
              </div>
              <h2 class="nav-link active my-3">{{ content.title }}</h2>
              <p v-html="content.subtitle"></p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <b-modal
      id="content-modal"
      ref="content-modal"
      size="md"
      hide-header
      hide-footer
      modal-class="base-page-modal"
      :body-class="['content-modal', contentModalClass]"
    >
      <b-row>
        <b-col v-for="(content, index) in page" :key="`content-modal-${index}`">
          <div class="text-center content-icon" :class="{ active: selectedContentIndex === index }">
            <div
              class="content-img-bg mx-auto"
              :key="`content-img-${index}`"
              @click="onContentClick(index)"
            >
              <img :src="content.img" alt="" />
            </div>
            <h6 class="my-2 content-title">{{ content.title }}</h6>
          </div>
        </b-col>

        <transition name="fade" mode="out-in">
          <div
            class="mx-4 mt-4"
            :class="contentClass"
            :key="`content-body-${selectedContentIndex}`"
          >
            <slot name="selected-content"></slot>
          </div>
        </transition>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    page: {
      type: Array,
      required: true
    },
    contentModalClass: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedContentIndex: 0
    }
  },
  methods: {
    onContentTileClick(index) {
      let self = this
      self.selectedContentIndex = index
      self.$refs['content-modal'].show()
      self.$emit('selectedIndex', index)
    },
    onContentClick(index) {
      let self = this
      self.selectedContentIndex = index
      self.$emit('selectedIndex', index)
    }
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
}

.fade-enter-active {
  transition-delay: 0.2s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
